<script setup lang="ts">
import type {
  Fit,
  ImageFormat,
  Modifier,
  Provider,
} from '../../types/nuxt-responsive-picture';
import type { VNodeRef } from 'vue';

withDefaults(defineProps<{
  alt: string;
  enableLazyLoading: boolean;
  fit: Fit | '';
  format: ImageFormat | '';
  height: number | 'auto';
  imageSrc: string;
  modifiers?: Modifier;
  objectFit: string;
  preload: boolean;
  provider?: Provider;
  quality?: number;
  sizes: string;
  width: number;
}>(), { alt: '',enableLazyLoading: true,fit: '',format: '',height: 0,modifiers: () => ({}),quality: 85,width: 0, })

const emit = defineEmits<{
  error: [];
  loaded: [];
}>();

const imgRef = ref<VNodeRef | null>(null);

onMounted(() => {
  if (!imgRef.value) {
    return;
  }

  const element = imgRef.value.$el as HTMLImageElement;
  nextTick(() => {
    if (!element || !element.complete) {
      return;
    }
    emit('loaded');
  });
});

const hideImageOnError = ref(false);

const loading = computed(() => (__props.enableLazyLoading
  ? 'lazy'
  : 'eager'
));

const onImageLoaded = () => {
  hideImageOnError.value = false;
  emit('loaded');
};

const onError = () => {
  hideImageOnError.value = true;
  emit('error');
};
</script>

<template>
  <nuxt-img
    v-if="!hideImageOnError && imageSrc"
    ref="imgRef"
    class="nuxt-responsive-image__image size-full"
    :alt="alt"
    :fit="fit"
    :format="format"
    :height="height"
    :loading="loading"
    :modifiers="modifiers"
    :provider="provider"
    :quality="quality"
    :sizes="sizes"
    :src="imageSrc"
    :style="{ objectFit }"
    :width="width"
    v-bind="{
      ...(enableLazyLoading ? { decoding: 'async' } : {}),
      ...(preload ? { fetchpriority: 'high' } : {})
    }"
    @error="onError"
    @load="onImageLoaded"
  />
</template>

<script setup lang="ts">
import type {
  ImageFormat,
  Provider,
} from '../../types/nuxt-responsive-picture';

const $img = useImage();

withDefaults(defineProps<{
  format: ImageFormat | '';
  media: string;
  preload: boolean;
  provider?: Provider;
  quality: number;
  sizes: string;
  src: string;
  width?: number;
}>(), { format: '',media: '(min-width: 0)',preload: false,width: 0, })

const cSizes = computed(() => {
  const widthToCalculate = __props.width > 0
    ? { width: __props.width }
    : {};

  const sizesParam = __props.sizes || {};

  return $img.getSizes(__props.src, {
    modifiers: {
      format: __props.format,
      quality: __props.quality,
      ...widthToCalculate,
    },
    provider: __props.provider,
    sizesParam,
  });
});

if (__props.preload) {
  useHead({
    link: [
      {
        as: 'image',
        imagesrcset: cSizes.value.srcset,
        media: __props.media,
        rel: 'preload',
        sizes: cSizes.value.sizes,
      },
    ],
  });
}
</script>
<template>
  <source
    :media="media"
    :sizes="cSizes.sizes"
    :srcset="cSizes.srcset"
  >
</template>
